import React, { useState } from "react";
import Bar from "./icons/Bar";
import Close from "./icons/Close";
import logo from "./img/logo.svg";
import { NavLink } from "react-router-dom";
const Header = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [sticky, setSticky] = useState(false);

	const handleScroll = () => {
		const position = window.pageYOffset;
		if (position < 100) {
			setSticky(false);
		} else {
			setSticky(true);
		}
	};

	window.addEventListener("scroll", handleScroll);
	return (
		<>
			<header className={`${sticky ? "sticky " : ""}`}>
				<div className="container">
					<div className="header-wrapper d-flex justify-content-between align-items-center">
						<a href="https://comitty.be" className="logo">
							<img src={logo} alt="" />
						</a>
						<div
							className="header-bar d-md-none"
							onClick={() => setMenuOpen(!menuOpen)}
						>
							{menuOpen ? <Close /> : <Bar />}
						</div>
						<div className={`menu-area ${menuOpen ? "active" : ""}`}>
							<ul className="mb-0 menu">
								<li>
									<a href="https://comitty.be/blog/">Notre Blog</a>
								</li>
								<li>
									<a href="https://comitty.be">En savoir plus</a>
								</li>
								{/* <li>
									<NavLink to="/login">Login</NavLink>
								</li> */}
							</ul>
							{/* <div className="login-btns">
								<a href="#0" className="header-btn btn-outline">
									<span>Login</span>
								</a>
							</div> */}
						</div>
					</div>
				</div>
			</header>
		</>
	);
};

export default Header;
