import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { useAlert } from "react-alert";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import Modal from "react-bootstrap/Modal";

import { db } from "../../firebase";
import Dropdown from "../Dropdown";
import Input from "../Input";
import { ReactComponent as AngleLeft } from "../../images/AngleLeft.svg";
import { Icons } from "../../images/icons";

import "./ManageOrg.scss";

function ManageOrg({ selectedOrg }) {
  const { t } = useTranslation("manage");
  const userStore = useSelector((state) => state.userReducer);

  const [method, setMethod] = useState("Cash");
  const [loader, setLoader] = useState(false);

  const [orgLiquidity, setOrgLiquidity] = useState(
    selectedOrg?.liquidity ? selectedOrg?.liquidity : ""
  );
  const [orgLiquidityHistory, setOrgLiquidityHistory] = useState(
    selectedOrg?.liquidityHistory ? selectedOrg?.liquidityHistory : []
  );
  const [newLiquidity, setNewLiquidity] = useState("");
  const [maxLiquidity, setMaxLiquidity] = useState("");
  const [maxOrgLiquidity, setMaxOrgLiquidity] = useState(
    selectedOrg?.maxLiquidity ? selectedOrg?.maxLiquidity : ""
  );
  const [comment, setComment] = useState("");
  const [orgSlateActive, setOrgSlateActive] = useState(
    selectedOrg?.slate ? selectedOrg?.slate : false
  );
  const [vivawalletActivated, setVivawalletActivated] = useState(
    selectedOrg?.vivawalletActivated ? selectedOrg?.vivawalletActivated : false
  );
  const [fastpassActivated, setFastpassActivated] = useState(
    selectedOrg?.fastpassActivated ? selectedOrg?.fastpassActivated : false
  );
  const [printingActivated, setPrintingActivated] = useState(
    selectedOrg?.printingActivated ? selectedOrg?.printingActivated : false
  );

  const alert = useAlert();

  useEffect(() => {
    setOrgLiquidity(selectedOrg?.liquidity || "");
    setOrgLiquidityHistory(selectedOrg?.liquidityHistory || []);
    setMaxOrgLiquidity(selectedOrg?.maxLiquidity || "");
    setOrgSlateActive(selectedOrg?.slate || false);
    setVivawalletActivated(selectedOrg?.vivawalletActivated || false);
    setFastpassActivated(selectedOrg?.fastpassActivated || false);
    setPrintingActivated(selectedOrg?.printingActivated || false);
    // Reset other states as necessary
  }, [selectedOrg]);

  const updateLiquidityHandler = async (clean) => {
    setLoader(true);
    if (newLiquidity) {
      const newHistory = [...orgLiquidityHistory];
      const updateAmount = orgLiquidity
        ? parseFloat(newLiquidity - orgLiquidity)
        : parseFloat(newLiquidity);
      newHistory.push({
        amount: updateAmount,
        comment: comment,
        user: userStore?.users.username,
      });

      const orgDocRef = doc(db, "Organizations", selectedOrg.id);

      try {
        await updateDoc(orgDocRef, {
          liquidity: parseFloat(newLiquidity),
          liquidityHistory: newHistory,
        });
        alert.show(t('liquidity_updated'), {
          type: "success",
        });
        setOrgLiquidity(newLiquidity);
        setOrgLiquidityHistory(newHistory);
        setNewLiquidity("");
        setComment("");
      } catch (err) {
        alert.show(t('liquidity_not_updated'), {
          type: "error",
        });
        console.error("Error updating document: ", err);
      }
    } else {
      alert.show(t('enter_new_amount'), {
        type: "warning",
      });
    }
    setLoader(false);
  };

  const updateMaxLiquidityHandler = async (clean) => {
    setLoader(true);
    if (maxLiquidity) {
      const orgDocRef = doc(db, "Organizations", selectedOrg.id);

      try {
        await updateDoc(orgDocRef, {
          maxLiquidity: parseFloat(maxLiquidity),
        });
        alert.show(t('limit_updated'), {
          type: "success",
        });
        setMaxOrgLiquidity(maxLiquidity);
        setMaxLiquidity("");
      } catch (err) {
        alert.show(t('limit_not_updated'), {
          type: "error",
        });
        console.error("Error updating document: ", err);
      }
    } else {
      alert.show(t('enter_new_amount'), {
        type: "info",
      });
    }
    setLoader(false);
  };

  const updateSlateHandler = async (active) => {
    setLoader(true);

    const orgDocRef = doc(db, "Organizations", selectedOrg.id);

    try {
      await updateDoc(orgDocRef, {
        slate: active,
      });
      alert.show(t('slate_updated'), {
        type: "success",
      });
      setOrgSlateActive(active);
    } catch (err) {
      alert.show(t('slate_not_updated'), {
        type: "error",
      });
      console.error("Error updating document: ", err);
    }
    setLoader(false);
  };

  const updateVivaHandler = async (active) => {
    setLoader(true);

    const orgDocRef = doc(db, "Organizations", selectedOrg.id);

    try {
      await updateDoc(orgDocRef, {
        vivawalletActivated: active,
      });
      alert.show(t('payment_provider_updated'), {
        type: "success",
      });
      //setVivawalletActivated(active);
    } catch (err) {
      alert.show(t('payment_provider_not_updated'), {
        type: "error",
      });
      console.error("Error updating document: ", err);
    }
    setLoader(false);
  };

  const updateFastpassHandler = async (active) => {
    setLoader(true);

    const orgDocRef = doc(db, "Organizations", selectedOrg.id);

    try {
      await updateDoc(orgDocRef, {
        fastpassActivated: active,
      });
      alert.show(t('fastpass_updated'), {
        type: "success",
      });
    } catch (err) {
      alert.show(t('fastpass_not_updated'), {
        type: "error",
      });
      console.error("Error updating document: ", err);
    }
    setLoader(false);
  };

  const updatePrintingHandler = async (active) => {
    setLoader(true);

    const orgDocRef = doc(db, "Organizations", selectedOrg.id);

    try {
      await updateDoc(orgDocRef, {
        printingActivated: active,
      });
      alert.show(t('printing_updated'), {
        type: "success",
      });
    } catch (err) {
      alert.show(t('printing_not_updated'), {
        type: "error",
      });
      console.error("Error updating document: ", err);
    }
    setLoader(false);
  };

  return (
    <div className="amountMainCard themeCard right-side-bar-content">
      <h4>{t("configuration")}</h4>
      <div className="priceMain card1">
        <div className="price">{orgLiquidity || "?"}€</div>
        <div className="liquidate">{t("cash_fund")}</div>
        <div className="max">
          {t("max_liquidity")}: {maxOrgLiquidity ? maxOrgLiquidity : "?"}€
        </div>
      </div>
      <div className="item">
        <h5 className="subtitle">{t("payment_methods")}</h5>
        <div className="text">{t("choose_payment_method")}</div>
        <div className="mb-28 gap-12px d-flex filter-buttons">
          <button
            type="button"
            className={`themeBtn m-0 w-100 pos-submit-btn ${
              method == "Cash" ? "active btn-success" : ""
            }`}
            onClick={() => setMethod("Cash")}
          >
            {t("cash")}
          </button>
          <button
            type="button"
            className={`themeBtn m-0 w-100 pos-submit-btn ${
              method == "Card" ? "active btn-option" : ""
            }`}
            onClick={() => setMethod("Card")}
          >
            {t("electronic")}
          </button>
        </div>
      </div>

      {method === "Cash" ? (
        <div>
          <div className="item">
            <h5 className="subtitle">{t("activate_debt_slate")}</h5>
            <div className="text">{t("choose_payment_method")}</div>
            <div className="mb-28 gap-12px d-flex filter-buttons">
              <button
                type="button"
                className={`themeBtn m-0 w-100 pos-submit-btn ${
                  !orgSlateActive ? "active" : ""
                }`}
                onClick={() => {
                  updateSlateHandler(false);
                  setOrgSlateActive(false);
                }}
              >
                {t("disabled")}
              </button>
              <button
                type="button"
                className={`themeBtn m-0 w-100 pos-submit-btn ${
                  orgSlateActive ? "active" : ""
                }`}
                onClick={() => {
                  updateSlateHandler(true);
                  setOrgSlateActive(true);
                }}
              >
                {t("enabled")}
              </button>
            </div>
          </div>
          <div className="item">
            <form onSubmit={(e) => e.preventDefault()}>
              <h5 className="subtitle">{t("update_liquidity")}</h5>
              <div className="text">{t("update_cash_fund_amount")}</div>
              <div className="mb-20">
                <Input
                  label={t("new_amount")}
                  placeholder="100€"
                  id="newLiquidity"
                  type="number"
                  value={newLiquidity}
                  onChange={(e) => setNewLiquidity(e.target.value)}
                />
              </div>
              <div className="mb-20">
                <Input
                  label={t("comment_optional")}
                  placeholder={t("comment")}
                  id="comment"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
              <button
                onClick={updateLiquidityHandler}
                className="themeBtn m-0 w-100 pos-submit-btn"
              >
                {t("update")}
              </button>
            </form>
          </div>
          <div className="item">
            <h5 className="subtitle">{t("maximum_liquidity")}</h5>
            <div className="text">{t("alert_when_liquidity_reaches")}</div>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="mb-20">
                <Input
                  label={t("max_liquidity")}
                  placeholder="250 €"
                  id="maxLiquidity"
                  type="number"
                  value={maxLiquidity}
                  onChange={(e) => setMaxLiquidity(e.target.value)}
                />
              </div>
              <button
                onClick={updateMaxLiquidityHandler}
                className="themeBtn m-0 w-100 pos-submit-btn"
              >
                {t("update")}
              </button>
            </form>
          </div>
          <div className="item">
            <h5 className="subtitle">{t("update_history")}</h5>
            <div className="text">{t("be_notified_when_liquidity")}</div>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="d-flex flex-column gap-12px mb-20">
                {orgLiquidityHistory.map((item, index) => (
                  <div className="right-sidebar-history-item" key={index}>
                    <div className="cont">
                      <h6>{item.user}</h6>
                      <p className="m-0">{item.comment}</p>
                    </div>
                    <div
                      className={`price ${
                        item.amount > 0 ? "text-success" : "text-danger"
                      }`}
                    >
                      {item.amount.toFixed(2)} €
                    </div>
                  </div>
                ))}
              </div>
              <button
                type="submit"
                className="themeBtn m-0 w-100 pos-submit-btn"
              >
                {t("delete")}
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div>
          <div className="item">
            <h5 className="subtitle">{t("payment_provider")}</h5>
            <div className="text">{t("choose_card_payment_provider")}</div>
            <div className="mb-28 gap-12px d-flex filter-buttons">
              <button
                type="button"
                className={`themeBtn m-0 w-100 pos-submit-btn ${
                  !vivawalletActivated ? "active" : ""
                }`}
                onClick={() => {
                  updateVivaHandler(false);
                  setVivawalletActivated(false);
                }}
              >
                {t("sumup")}
              </button>
              <button
                type="button"
                className={`themeBtn m-0 w-100 pos-submit-btn ${
                  vivawalletActivated ? "active" : ""
                }`}
                onClick={() => {
                  updateVivaHandler(true);
                  setVivawalletActivated(true);
                }}
              >
                {t("viva_com")}
              </button>
            </div>
          </div>
          <div className="item">
            <h5 className="subtitle">{t("activate_fastpass")}</h5>
            <div className="text">{t("allow_clients_order_via_phone")}</div>
            <div className="mb-28 gap-12px d-flex filter-buttons">
              <button
                type="button"
                className={`themeBtn m-0 w-100 pos-submit-btn ${
                  !fastpassActivated ? "active" : ""
                }`}
                onClick={() => {
                  updateFastpassHandler(false);
                  setFastpassActivated(false);
                }}
              >
                {t("disabled")}
              </button>
              <button
                type="button"
                className={`themeBtn m-0 w-100 pos-submit-btn ${
                  fastpassActivated ? "active" : ""
                }`}
                onClick={() => {
                  updateFastpassHandler(true);
                  setFastpassActivated(true);
                }}
              >
                {t("enabled")}
              </button>
            </div>
          </div>
          <div className="item">
            <h5 className="subtitle">{t("activate_receipt_printing")}</h5>
            <div className="text">{t("printing_only_compatible")}</div>
            <div className="mb-28 gap-12px d-flex filter-buttons">
              <button
                type="button"
                className={`themeBtn m-0 w-100 pos-submit-btn ${
                  !printingActivated ? "active" : ""
                }`}
                onClick={() => {
                  updatePrintingHandler(false);
                  setPrintingActivated(false);
                }}
              >
                {t("disabled")}
              </button>
              <button
                type="button"
                className={`themeBtn m-0 w-100 pos-submit-btn ${
                  printingActivated ? "active" : ""
                }`}
                onClick={() => {
                  updatePrintingHandler(true);
                  setPrintingActivated(true);
                }}
              >
                {t("enabled")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const history = [
  {
    title: "Android",
    subtitle: "Bottle of Cafe",
    price: -23,
  },
  {
    title: "Android",
    subtitle: "Bottle of Cafe",
    price: -23,
  },
  {
    title: "Android",
    subtitle: "Bottle of Cafe",
    price: -23,
  },
  {
    title: "Android",
    subtitle: "Bottle of Cafe",
    price: 500,
  },
  {
    title: "Android",
    subtitle: "Bottle of Cafe",
    price: -23,
  },
];

export default ManageOrg;
