import React from "react";
import ReactDropdown from "react-dropdown";

const Dropdown = ({ label, placeholder, options, value, onChange, ...rest }) => {
  return (
    <>
      <label className="form-label __form-label" htmlFor={label}>
        {label}
      </label>
      <ReactDropdown
        options={options}
        onChange={onChange}
        value={value}
        className="form-control __form-control custom-dropdown"
        placeholder={placeholder}
        menuClassName="custom-dropdown-menu"
        {...rest}
      />
    </>
  );
};

export default Dropdown;
