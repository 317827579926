import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import "swiper/css";
import "swiper/css/free-mode";
import { FreeMode } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

import { db } from "../../../firebase";
import Input from "../../../components/Input";
import { ReactComponent as AngleLeft } from "../../../images/AngleLeft.svg";
import { ReactComponent as DeleteIcon } from "../../../images/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../images/EditIcon.svg";
import { ReactComponent as Plus } from "../../../images/Plus.svg";
import { ReactComponent as Beer } from "../../../images/Beers.svg";
import { ReactComponent as Softs } from "../../../images/Softs.svg";
import { ReactComponent as Food } from "../../../images/Fast-food.svg";
import { ReactComponent as HotDrinks } from "../../../images/hotDrinks.svg";
import { ReactComponent as Cocktails } from "../../../images/Cocktails.svg";
import { ReactComponent as Wine } from "../../../images/Wine.svg";
import { ReactComponent as Others } from "../../../images/Others.svg";
import { Icons } from "../../../images/icons";
import {
  BeersArray,
  CocktailsArray,
  FoodArray,
  HotDrinksArray,
  OtherArray,
  SoftsArray,
  WineArray,
} from "../../../utility/data";
import "./pos.scss";
import { Loader2 } from "../../../helper/loader";
import AddProduct from "../../../components/AddProducts/AddProduct";
import ManageOrg from "../../../components/ManageOrg/ManageOrg";

function POS({ organizations, orgId }) {
  const { t } = useTranslation("editpos");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [addNew, setAddNew] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [loader, setLoader] = useState(false);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();

  const handleCloseDelete = () => {
    setSelectedProduct();
    setShowDelete(false);
  };

  const getOrgProduct = async () => {
    //setLoader(true);
    let records = [];
    const q = query(collection(db, "products"), where("orgId", "==", orgId[0]));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.data().category)
      records.push(doc.data());
    });

    setProducts(records);
    setFilteredProducts(records);
    //setLoader(false);
  };

  const deleteProduct = async () => {
    setLoader(true);
    try {
      const productRef = doc(db, "products", selectedProduct.id);
      await deleteDoc(productRef);
      getOrgProduct();
      setSelectedProduct(null);
    } catch (error) {
      console.error("Error deleting product: ", error);
    }
    setLoader(false);
    setShowDelete(false);
  };

  useEffect(() => {
    setSelectedOrg(organizations.find((orga) => orga.id === orgId[0]));

    getOrgProduct();
  }, [orgId, addNew, selectedProduct]);

  useEffect(() => {
    if (selectedCategory === "All") {
      setFilteredProducts(products);
    } else {
      const newFilterProducts = products.filter(
        (product) => product.category === selectedCategory
      );
      setFilteredProducts(newFilterProducts);
    }
  }, [selectedCategory]);

  return (
    <>
      <div className="container-fluid dashboardMain">
        <div className="row mx-0 align-items-start">
          <div className="col-xl-8">
            <div className="card themeCard">
              <div className="card-header border-0 p-sm-20px pb-0 pb-sm-0">
                <h5 className="m-0 mb-4 tableHeading">
                  {selectedOrg?.organization}
                </h5>
                <div className="filterRow">
                  <div
                    className={
                      selectedCategory === "All" ? "filBtn active" : "filBtn"
                    }
                    onClick={() => setSelectedCategory("All")}
                  >
                    <span>{t("all")}</span>
                  </div>
                  <div
                    className={
                      selectedCategory == "Beers" ? "filBtn active" : "filBtn"
                    }
                    onClick={() => setSelectedCategory("Beers")}
                  >
                    <Beer />
                    <span>{t("beers")}</span>
                  </div>
                  <div
                    className={
                      selectedCategory == "Softs" ? "filBtn active" : "filBtn"
                    }
                    onClick={() => setSelectedCategory("Softs")}
                  >
                    <Softs />
                    <span>{t("softs")}</span>
                  </div>
                  <div
                    className={
                      selectedCategory == "Food" ? "filBtn active" : "filBtn"
                    }
                    onClick={() => setSelectedCategory("Food")}
                  >
                    <Food />
                    <span>{t("food")}</span>
                  </div>
                  <div
                    className={
                      selectedCategory == "Cocktails"
                        ? "filBtn active"
                        : "filBtn"
                    }
                    onClick={() => setSelectedCategory("Cocktails")}
                  >
                    <Cocktails />
                    <span>{t("cocktails")}</span>
                  </div>
                  <div
                    className={
                      selectedCategory == "Wine" ? "filBtn active" : "filBtn"
                    }
                    onClick={() => setSelectedCategory("Wine")}
                  >
                    <Wine />
                    <span>{t("wine")}</span>
                  </div>
                  <div
                    className={
                      selectedCategory == "Hot drinks"
                        ? "filBtn active"
                        : "filBtn"
                    }
                    onClick={() => setSelectedCategory("Hot drinks")}
                  >
                    <HotDrinks />
                    <span>{t("hot_drinks")}</span>
                  </div>
                  <div
                    className={
                      selectedCategory == "Other" ? "filBtn active" : "filBtn"
                    }
                    onClick={() => setSelectedCategory("Other")}
                  >
                    <Others />
                    <span>{t("others")}</span>
                  </div>
                </div>
                <div className="d-flex flex-wrap gap-6 justify-content-between align-items-center py-4">
                  <h6 className="m-0 tableHeading m-0">{t("your_products")}</h6>
                  <button
                    className="themeBtn py-1 min-h-40 rounded-full m-0 text-capitalize min-w-131px"
                    type="button"
                    onClick={() => {
                      setAddNew(true);
                      setSelectedProduct();
                    }}
                  >
                    <Plus /> {t("add")}
                  </button>
                </div>
              </div>
              <div className="card-body p-sm-3 pt-0 pt-sm-0">
                <div className="products-grid">
                  {filteredProducts
                    .sort((a, b) => Number(a.price) - Number(b.price))
                    .map((item) => (
                      <div className="productCard" key={item.id}>
                        <button
                          type="button"
                          className="edit"
                          onClick={() => {
                            setAddNew(false);
                            setSelectedProduct(item);
                            setAddNew(true);
                          }}
                        >
                          <EditIcon />
                        </button>
                        <button
                          type="button"
                          className="delete"
                          onClick={() => {
                            setSelectedProduct(item);
                            setShowDelete(true);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                        <div className="productImg">
                          <img
                            src={
                              item?.customIcon
                                ? item.category === "Food"
                                  ? FoodArray.find(
                                      (i) => i.name === item?.customIcon
                                    ).icon
                                  : item.category === "Softs"
                                  ? SoftsArray.find(
                                      (i) => i.name === item?.customIcon
                                    ).icon
                                  : item.category === "Beers"
                                  ? BeersArray.find(
                                      (i) => i.name === item?.customIcon
                                    ).icon
                                  : item.category === "Cocktails"
                                  ? CocktailsArray.find(
                                      (i) => i.name === item?.customIcon
                                    ).icon
                                  : item.category === "Wine"
                                  ? WineArray.find(
                                      (i) => i.name === item?.customIcon
                                    ).icon
                                  : item.category === "Hot drinks"
                                  ? HotDrinksArray.find(
                                      (i) => i.name === item?.customIcon
                                    ).icon
                                  : item.category === "Other" &&
                                    OtherArray.find(
                                      (i) => i.name === item?.customIcon
                                    ).icon
                                : item.category == "Food"
                                ? Icons.food
                                : item.category == "Softs"
                                ? Icons.soft
                                : item.category == "Beers"
                                ? Icons.beer
                                : item.category == "Cocktails"
                                ? Icons.cocktail
                                : item.category == "Hot drinks"
                                ? Icons.drink
                                : item.category == "Other" && Icons.others
                            }
                            alt=""
                          />
                        </div>
                        <div className="productDetails">
                          <h5>{item.title}</h5>
                          {item.outOfStock ? (
                            <p className="outOfStock">{t("out_of_stock")}</p>
                          ) : (
                            <div className="horizontal">
                              {item?.ticketNumber > 0 && item?.ticketColor && (
                                <div className="ticket-info">
                                  <p className="ticket-number">
                                    {item.ticketNumber}
                                  </p>
                                  <div
                                    className="color-circle"
                                    style={{
                                      backgroundColor: item.ticketColor,
                                    }}
                                  ></div>
                                </div>
                              )}
                              {item?.secondTicketNumber > 0 &&
                                item?.secondTicketColor && (
                                  <div className="ticket-info">
                                    <p className="ticket-number">
                                      {item.secondTicketNumber}
                                    </p>
                                    <div
                                      className="color-circle"
                                      style={{
                                        backgroundColor: item.secondTicketColor,
                                      }}
                                    ></div>
                                  </div>
                                )}
                              <p className="price">{item.price}€</p>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            {addNew ? (
              <AddProduct
                orgId={orgId[0]}
                selectedProduct={selectedProduct}
                setAddNew={setAddNew}
              />
            ) : (
              <div>
                {selectedOrg && <ManageOrg selectedOrg={selectedOrg} />}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("are_you_sure")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="liquidityEditForm">
            <button className="themeBtn w-100 mt-4" onClick={deleteProduct}>
              {t("delete")}
            </button>
            <button
              className="themeBtn btn-dark w-100 mt-4"
              onClick={handleCloseDelete}
            >
              {t("cancel")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {loader && <Loader2 />}
    </>
  );
}

export default POS;

export { POS };
