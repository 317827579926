import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import QRCode from "qrcode";

import { Icons } from "../../images/icons";
import CareModal from "./CareModal";
import {
  getOrgOrder,
  updateOrgOrder,
  getAds,
  getOrganization,
} from "../../utility";
import LoadingSpinner from "../../components/spinners/DrinkSpinner";
import { emptyWholeCart } from "../../redux/cart/reducer";

import styled from "styled-components";

const Button = styled.div`
  user-select: none;
  cursor: pointer;
  background: var(--gradient);
  border-radius: 15px;
  color: white;
  padding: 20px 30px;
  position: relative;
  width: 100%;
  height: 50px;
  margin-top: 9px;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 16px;
  font-weight: 700;
  font-family: "BR Frima Bold";
  height: 60px;
  text-transform: uppercase;
`;

const Progress = styled.div`
  margin: -20px -30px;
  background-color: pink;
  border-radius: 15px;
  height: 60px;
  position: absolute;
  width: 0;
  transition: width 0s;
  opacity: 0.5;
  &.start,
  &.done {
    width: 100%;
    transition: width 2s;
  }
`;

const Container = styled.div`
  text-align: center;
`;

function useLongPress(callback = () => {}, ms = 150) {
  const [startLongPress, setStartLongPress] = useState(false);

  useEffect(() => {
    let timerId;
    if (startLongPress) {
      timerId = setTimeout(callback, ms);
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [startLongPress]);

  const start = useCallback(() => {
    setStartLongPress(true);
  }, []);
  const stop = useCallback(() => {
    setStartLongPress(false);
  }, []);

  return [
    startLongPress,
    {
      onMouseDown: start,
      onMouseUp: stop,
      onMouseLeave: stop,
      onTouchStart: start,
      onTouchEnd: stop,
    },
  ];
}

const ProgressButton = (props) => {
  const [startLongPress, backspaceLongPress] = useLongPress(
    props.longPressBackspaceCallback,
    1500
  );
  let className = "progress";
  if (startLongPress) {
    className += " start";
  }

  return (
    <Button {...backspaceLongPress}>
      <Progress className={className}></Progress>
      <Container>Terminer press +2s</Container>
    </Button>
  );
};

const CashlessSuccess = () => {
  const [careModalOpen, setCareModalOpen] = useState(false);
  const navigate = useNavigate();
  const { orgId, orderId } = useParams();
  const [order, setOrder] = useState();
  const [ad, setAd] = useState();
  const [automaticValidation, setAutomaticValidation] = useState(false);
  const [qrTicket, setQrTicket] = useState();
  const alert = useAlert();
  const dispatch = useDispatch();

  const fetchAdvertisement = async () => {
    const fetchedAd = await getAds("bannerAd");
    setAd(fetchedAd.ad);
  };

  const fetchOrder = async () => {
    const savedOrder = await getOrgOrder(orgId, orderId);
    if (savedOrder) {
      setOrder(savedOrder.order);
    } else {
      alert.show("Impossible de charger cette commande.", {
        type: "error",
      });
    }
  };

  const handleConfirmation = async () => {
    let updatedOrder = { ...order, received: true };
    setOrder(updatedOrder);
    dispatch(emptyWholeCart());
    try {
      await updateOrgOrder(orgId, orderId);
    } catch (error) {
      alert.show("Impossible de confirmer cette commande.", {
        type: "error",
      });
    }
  };

  const setQrCodeHandler = async () => {
    try {
      const qrcode = await QRCode.toDataURL(orderId, {
        scale: 12,
      });
      setQrTicket(qrcode);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (orderId) {
      setQrCodeHandler();
      fetchOrder();
      fetchAdvertisement();
    } else {
      alert.show("Impossible de charger cette commande.", {
        type: "error",
      });
    }
  }, [orderId]); // Dependencies for useEffect

  useEffect(() => {
    let intervalId = null; // Declare intervalId outside to make it accessible for clearing

    if (order && !order.received) {
      // Set an interval only if 'order.received' is not true
      intervalId = setInterval(() => {
        fetchOrder().then((updatedOrder) => {
          console.log("Commande rafraîchie");
          if (updatedOrder.received) {
            console.log("Order received, stopping interval.");
            clearInterval(intervalId); // Stop the interval if order is received
          }
        });
      }, 600000); // 600000 ms = 10 minutes
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Clean up the interval when the component unmounts or order changes
      }
    };
  }, [order]); // React to changes in 'order'

  return (
    <>
      {order ? (
        <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
          <div className="page-header">
            <div className="container">
              <h2
                className="title"
                style={{ color: order.received ? "#27AE60" : "white" }}
              >
                {order.received ? "Vous êtes servi :)" : "Rendez-vous au bar !"}
              </h2>
              <div>
                {order.received
                  ? "Votre commande est terminée."
                  : "Allez au bar et montrez cet écran pour récupérer votre commande."}
              </div>
            </div>
          </div>
          <div className="container px-20px py-4">
            <div className="summary-title">
              Résumé de la commande N°{order.createdAt.toString().slice(-4)}
            </div>
            <div className="summary">
              <ul>
                {order.products.map((item, i) => (
                  <li key={i}>
                    <span>{item.title}</span>
                    <span className="count">x {item.quantity}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mt-auto">
            <div className="page-header pt-3 pb-4">
              <div className="container">
                <div className="total--price mt-0 mb-12px">
                  <span>Total</span>
                  <span style={{ color: "#27AE60" }}>
                    {order.totalAmount.toFixed(2)}€
                  </span>
                </div>
                {order.received && ad && (
                  <div
                    className="text-center mb-12px"
                    onClick={() => window.open(ad.url, "_blank")}
                  >
                    <img src={ad.image} alt="promo" className="mw-100" />
                  </div>
                )}

                {order.received ? (
                  <button
                    type="button"
                    className="submitBtn submitBtn2 m-0"
                    style={{
                      background:
                        "linear-gradient(91.24deg, #00C29F 0.26%, #009FC2 99.58%)",
                    }}
                    onClick={() => {
                      navigate(`/cashless/${orgId}`);
                    }}
                  >
                    commander encore
                  </button>
                ) : (
                  <div>
                    <div>
                      <div className="text-center">
                        <div className="mb-3 cashless--title">Montrez ce Boutton au bar !</div>
                        {/* <img
                          src={qrTicket}
                          alt=""
                          className="qrcode-ticket mx-auto"
                        /> */}
                      </div>
                      <div className="mb-12px">
                        {" "}
                        Ne pressez ce bouton uniquement si vous êtes servis !{" "}
                      </div>
                      <ProgressButton
                        longPressBackspaceCallback={() => handleConfirmation()}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh", width: "100%" }}
        >
          <LoadingSpinner />
        </div>
      )}
      <CareModal open={careModalOpen} setOpen={setCareModalOpen} />
    </>
  );
};

export default CashlessSuccess;
