import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";

import { getOrganization, getOrgProducts } from "../../utility";
import {
  addItemToCart,
  addTenItemToCart,
  getCartArray,
  removeItemFromCart,
} from "../../redux/cart/reducer";
import { AllIcon, BasketIcon, CardIcon } from "../../components/Icon";
import CashlessModal from "./CashlessModal";
import LoadingSpinner from "../../components/spinners/DrinkSpinner";
import {
  BeersArray,
  CocktailsArray,
  FoodArray,
  HotDrinksArray,
  OtherArray,
  SoftsArray,
  WineArray,
  Category,
} from "../../utility/data";
import { Icons } from "../../images/icons";
import CareModal from "./CareModal";

const Cashless = () => {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [careModalOpen, setCareModalOpen] = React.useState(false);
  const [active, setActive] = useState("all");
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState([]);
  const [orgName, setOrgName] = useState("Chargement...");
  //const [total, setTotal] = useState(0);
  const [org, setOrg] = useState({});
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [bailProducts, setBailProducts] = useState([]);

  const navigate = useNavigate();
  const { orgId } = useParams();
  const cartStore = useSelector((state) => state.cartReducer.cartArray);
  const alert = useAlert();

  let total = 0;

  cartStore?.map((i) => {
    total = parseFloat(i?.totalDue + total);
  });

  const getOrganizationHandler = async () => {
    setLoading(true);
    try {
      const orgData = await getOrganization(orgId);
      if (!orgData.org.stripeId || !orgData.org.stripeActivated) {
        alert.show("Cette caisse n'est pas active.", {
          type: "error",
        });
        navigate("/");
      }
      const loadedProducts = await getOrgProducts(orgId);

      if (loadedProducts?.products) {
        const availableProducts = loadedProducts.products.filter(
          (product) => !product.outOfStock
        );
        setProducts(availableProducts);
        setFilteredProducts(
          availableProducts.sort((a, b) => Number(a.price) - Number(b.price))
        );

        const findBails = availableProducts.filter(
          (product) =>
            product.title.includes("caution") ||
            product.title.includes("bail") ||
            product.title.includes("Caution") ||
            product.title.includes("Bail") ||
            product.title.includes("Kaution") ||
            product.title.includes("Borg") ||
            product.title.includes("menu")
        );
        setBailProducts(findBails);
      } else {
        alert.show("Ce menu n'a pas de produits.", {
          type: "error",
        });
      }
      setOrg(orgData.org);
      setOrgName(orgData.org.organization);
    } catch (err) {
      console.error(err);
      //navigate("/");
    }
    setLoading(false);
  };

  useEffect(() => {
    getOrganizationHandler();
  }, [orgId]);

  useEffect(() => {
    setLoading(true);

    if (active === "all") {
      setFilteredProducts(
        products.sort((a, b) => Number(a.price) - Number(b.price))
      );
    } else {
      const activeProducts = products.filter(
        (product) => product.category === active
      );
      setFilteredProducts(
        activeProducts.sort((a, b) => Number(a.price) - Number(b.price))
      );
    }
    setLoading(false);
  }, [active]);

  return (
    <>
      <div className="d-flex flex-column" style={{ minHeight: "100dvh" }}>
        <div className="page-header pb-3 position-sticky top-0 zi-50">
          <div className="container">
            <h2 className="title text-center mb-3 text-30">{orgName}</h2>
          </div>
          <div className="filter-menu-container d-flex">
            <ul className="filter-menu px-4 mx-auto my-0">
              <li
                key={0}
                className={active === "all" ? "active" : ""}
                onClick={() => setActive("all")}
              >
                <AllIcon />
              </li>

              {Category.map((item, index) => (
                <li
                  key={index}
                  className={active === item.value ? "active" : ""}
                  onClick={() => setActive(item.value)}
                >
                  <img
                    src={item.logo}
                    color="white"
                    height="50%"
                    width="50%"
                    alt=""
                  />
                  {item.icon}
                  {item.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {orgName === "Chargement..." ? (
          <LoadingSpinner height="40%" width="40%" />
        ) : (
          <div>
            <div className="container py-3 px-4">
              <div className="row g-3">
                {filteredProducts.map((item, i) => (
                  <div className="col-6 col-sm-4 col-md-3" key={i}>
                    <CaiseMenuBox
                      itemData={item}
                      cart={cartStore}
                      setCart={setCart}
                      addToCart={(item) => dispatch(addItemToCart(item))}
                      removeFromCart={(item) =>
                        dispatch(removeItemFromCart(item))
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="container position-sticky bottom-0 pb-2 gap-2 d-flex zi-50">
              <button
                type="button"
                className="btn-1 w-0 flex-grow"
                onClick={() =>
                  navigate(`/cashless/${orgId}/cart`, {
                    state: { bailProducts },
                  })
                }
              >
                <BasketIcon />
                {` Panier : ${total.toFixed(2)}€`}
              </button>
              <button
                type="button"
                className="btn-2 w-0 flex-grow"
                onClick={() => {
                  if (bailProducts.length > 0) {
                    setModalOpen(true);
                  } else {
                    if (total < 10) {
                      setCareModalOpen(true);
                    } else {
                      navigate(`/cashless/${orgId}/payment`);
                    }
                  }
                }}
              >
                <CardIcon />
                {" Paiement"}
              </button>
            </div>
          </div>
        )}

        <CashlessModal
          open={modalOpen}
          setOpen={setModalOpen}
          total={total}
          bails={bailProducts}
          setCareModalOpen={setCareModalOpen}
        />
        <CareModal open={careModalOpen} setOpen={setCareModalOpen} />
      </div>
    </>
  );
};

const CaiseMenuBox = (props) => {
  const { itemData, cart, setCart, addToCart, removeFromCart } = props;
  const [isInCart, setIsInCart] = useState(null);
  const [style, setStyle] = useState({ opacity: 1 });

  const timer = () => {
    setStyle({ opacity: 0.8 });
    setTimeout(() => {
      setStyle({ opacity: 1 });
    }, 100);
  };

  const handleClick = (payload) => {
    timer();

    addToCart(payload);
  };

  useEffect(() => {
    setIsInCart(cart?.find((item) => item.id === itemData?.id));
  }, [cart]);

  return (
    <>
      <div className="caise-menu-box" style={style}>
        <div className="click" onClick={() => handleClick(itemData)} />
        {isInCart && (
          <>
            <div className="close-1" onClick={() => removeFromCart(itemData)}>
              X
            </div>
            <div className="close-2">x{isInCart?.quantity}</div>
          </>
        )}
        <img
          src={
            itemData?.customIcon
              ? itemData.category === "Food"
                ? FoodArray.find((i) => i.name === itemData?.customIcon)
                    ?.icon || Icons.food
                : itemData.category === "Softs"
                ? SoftsArray.find((i) => i.name === itemData?.customIcon)
                    ?.icon || Icons.soft
                : itemData.category === "Beers"
                ? BeersArray.find((i) => i.name === itemData?.customIcon)
                    ?.icon || Icons.beer
                : itemData.category === "Cocktails"
                ? CocktailsArray.find((i) => i.name === itemData?.customIcon)
                    ?.icon || Icons.cocktail
                : itemData.category === "Wine"
                ? WineArray.find((i) => i.name === itemData?.customIcon)
                    ?.icon || Icons.wine
                : itemData.category === "Hot drinks"
                ? HotDrinksArray.find((i) => i.name === itemData?.customIcon)
                    ?.icon || Icons.drink
                : itemData.category === "Other"
                ? OtherArray.find((i) => i.name === itemData?.customIcon)
                    ?.icon || Icons.others
                : Icons.default
              : itemData.category === "Food"
              ? Icons.food
              : itemData.category === "Softs"
              ? Icons.soft
              : itemData.category === "Beers"
              ? Icons.beer
              : itemData.category === "Cocktails"
              ? Icons.cocktail
              : itemData.category === "Wine"
              ? Icons.wine
              : itemData.category === "Hot drinks"
              ? Icons.drink
              : itemData.category === "Other"
              ? Icons.others
              : Icons.default
          }
          alt=""
        />

        <h6 className="name">{itemData?.title}</h6>
        <h6 className="price">{itemData?.price}€</h6>
      </div>
    </>
  );
};

const filtermenu = [
  {
    title: "",
    icon: <AllIcon />,
    value: "all",
  },
  {
    title: "Bières",
    icon: <AllIcon />,
    value: "Bières",
  },
  {
    title: "Nourriture",
    icon: <AllIcon />,
    value: "Nourriture",
  },
  {
    title: "Clothes",
    icon: "",
    value: "Clothes",
  },
];

const data = [
  {
    img: "/img/menu/1.svg",
    name: "Pils",
    price: 2,
    id: "1",
  },
  {
    img: "/img/menu/2.svg",
    name: "Cola",
    price: 1.5,
    id: "2",
  },
  {
    img: "/img/menu/3.svg",
    name: "Kriek",
    price: 2,
    id: "3",
  },
  {
    img: "/img/menu/4.svg",
    name: "Chips",
    price: 1.5,
    id: "4",
  },
  {
    img: "/img/menu/5.svg",
    name: "Hamburger",
    price: 5,
    id: "5",
  },
  {
    img: "/img/menu/6.svg",
    name: "Eau plate",
    price: 1,
    id: "6",
  },
  {
    img: "/img/menu/1.svg",
    name: "Pils",
    price: 2,
    id: "7",
  },
  {
    img: "/img/menu/2.svg",
    name: "Cola",
    price: 1.5,
    id: "8",
  },
  {
    img: "/img/menu/3.svg",
    name: "Kriek",
    price: 2,
    id: "9",
  },
  {
    img: "/img/menu/4.svg",
    name: "Chips",
    price: 1.5,
    id: "10",
  },
  {
    img: "/img/menu/5.svg",
    name: "Hamburger",
    price: 5,
    id: "11",
  },
  {
    img: "/img/menu/6.svg",
    name: "Eau plate",
    price: 1,
    id: "12",
  },
];
export default Cashless;
