import React from "react";
import Facebook from "./icons/Facebook";
import Instagram from "./icons/Instagram";
import Twitter from "./icons/Twitter";
import Youtube from "./icons/Youtube";
import logo from "./img/footer.svg";
const Footer = () => {
	return (
		<>
			<footer>
				<div className="container">
					<a href="https://comitty.be" className="footer-logo">
						<img src={logo} alt="" />
					</a>
					{/* <ul className="social-icons">
						<li>
							<a href="#0">
								<Facebook />
							</a>
						</li>
						<li>
							<a href="#0">
								<Instagram />
							</a>
						</li>
						<li>
							<a href="#0">
								<Twitter />
							</a>
						</li>
						<li>
							<a href="#0">
								<Youtube />
							</a>
						</li>
					</ul> */}
					<ul className="footer-links">
						<li>
							<a href="https://yummy-technologies.be/">Copyright @2023 Yummy Technologies</a>
						</li>
						{/* <li>
							<a href="#0">Website term of use</a>
						</li> */}
						<li>
							<a href="https://yummy-technologies.be/privacy-policy">Privacy Policy</a>
						</li>
						<li>
							<a href="https://yummy-technologies.be/privacy-policy">Terms & Conditions</a>
						</li>
						<li>
							<a href="https://comitty.be/">FAQ & Contact</a>
						</li>
					</ul>
				</div>
			</footer>
		</>
	);
};

export default Footer;
