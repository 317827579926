import React from "react";
import { useTranslation } from "react-i18next";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <li className="nav-item mb-0 mt-auto">
      <div className="nav-link sidebar-Link fw-500 w-100">
        {/* <div className="mb-1">Version 1.0</div> */}
        <select
          className="form-select"
          onChange={(e) => changeLanguage(e.target.value)}
          value={i18n.language}
        >
          <option value="en">English</option>
          <option value="fr">Français</option>
          <option value="nl">Nederlands</option>
          {/* Add more languages as needed */}
        </select>
      </div>
    </li>
  );
}

export default LanguageSwitcher;
