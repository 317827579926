import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/free-mode";
import {
  collection,
  query,
  updateDoc,
  doc,
  setDoc,
  getDocs,
  getDoc,
  where,
} from "firebase/firestore";
import { useAlert } from "react-alert";

import { db } from "../../../firebase";
import Input from "../../../components/Input";
import Dropdown from "../../../components/Dropdown";
import "./organizations.scss";

const defaultLogo =
  "https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/logos%2FlogoGradient.png?alt=media&token=c6f128d4-5c84-4195-b437-f59c34c69591";

function OrganizationsPage({ organizations, orgId }) {
  const { t } = useTranslation('organization');
  const userStore = useSelector((state) => state.userReducer);

  const [loader, setLoader] = useState(false);
  const alert = useAlert();
  const [selectedOrg, setSelectedOrg] = useState("");
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [selectedActivity, setSelectedActivity] = useState("");
  const [email, setEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [members, setMembers] = useState([]);

  const activity = [
    {
      id: '1',
      label: t('sportClub'),
      value: 'Club de sport',
    },
    {
      id: '2',
      label: t('youthCommittee'),
      value: 'Comité de Jeunesse',
    },
    {
      id: '3',
      label: t('schoolCommittee'),
      value: 'Comité scolaire',
    },
    {
      id: '4',
      label: t('festivalCommittee'),
      value: 'Comité des fêtes',
    },
    {
      id: '5',
      label: t('folklore'),
      value: 'Folklore',
    },
    {
      id: '6',
      label: t('nonProfitOrganization'),
      value: 'ASBL',
    },
    {
      id: '7',
      label: t('eventCompany'),
      value: 'Entreprise',
    },
    {
      id: '8',
      label: t('other'),
      value: 'Autre',
    },
  ];
  
  const roles = [
    {
      id: '1',
      label: t('administrator'),
      value: 'Admin',
    },
    {
      id: '2',
      label: t('cashier'),
      value: 'Cashier',
    },
    {
      id: '3',
      label: t('steward'),
      value: 'Steward',
    },
  ];
  

  async function getAllOfCollectionWhere(collectionName, field, value) {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where(field, "==", value));
    const querySnapshot = await getDocs(q);
    const documents = [];
    querySnapshot.forEach((doc) => {
      documents.push({ id: doc.id, ...doc.data() });
    });
    return documents;
  }

  async function getMembersFromDb(foundOrg) {
    if (!foundOrg) {
      alert.show(t("alert.noOrgSelected"), { type: "error" });
      return;
    }

    let membersArrayTemp = [];

    const rles = ["adminUid", "cashierUid", "stewardUid"];
    const roleLabels = {
      adminUid: "admin",
      cashierUid: "cashier",
      stewardUid: "steward",
    };

    try {
      for (const role of rles) {
        for (const uid of foundOrg[role]) {
          const userDoc = await getDoc(doc(db, "Store", uid));
          if (userDoc.exists()) {
            membersArrayTemp.push({
              ...userDoc.data(),
              roleInOrg: roleLabels[role],
            });
          }
        }
      }
      setMembers(membersArrayTemp);
    } catch (error) {
      console.error(t("error.failedFetchMembers"), error);
      alert.show(t("error.failedLoadMembers"), { type: "error" });
    }
  }

  const saveForm = async () => {
    console.log(t("log.saving"));
    if (selectedOrg?.id && title && selectedActivity) {
      setLoader(true);
      const orgDocRef = doc(db, "Organizations", selectedOrg.id);

      try {
        await updateDoc(orgDocRef, {
          organization: title,
          activity: selectedActivity,
        });
        alert.show(t("alert.posUpdated"), {
          type: "success",
        });
      } catch (err) {
        alert.show(t("alert.posNotUpdated"), {
          type: "error",
        });
        console.error(t("error.updatingDoc"), err);
      }
      setLoader(false);
    } else {
      alert.show(t("alert.fillAllFields"), {
        type: "info",
      });
    }
  };

  const inviteHandler = async () => {
    const inviteEmail = email.toLowerCase();
    if (inviteEmail === "") {
      alert.show(t("alert.enterEmail"), {
        type: "info",
      });
      return;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inviteEmail)) {
      alert.show(t("alert.invalidEmail"), { type: "info" });
      return;
    }
    if (selectedRole === "") {
      alert.show(t("alert.chooseRole"), {
        type: "info",
      });
      return;
    }
    setLoader(true);
    const orgDocRef = doc(db, "Organizations", selectedOrg.id);

    const response = await getAllOfCollectionWhere(
      "Store",
      "email",
      inviteEmail
    );

    if (response.length > 0) {
      const uid = response[0].uid;
      const roleUidField = `${selectedRole.toLowerCase()}Uid`;
      if (selectedOrg[roleUidField]?.includes(uid)) {
        alert.show(t("alert.userExists"), { type: "info" });
        setLoader(false);
      } else {
        let temp = [...(selectedOrg[roleUidField] || [])];
        temp.push(uid);

        try {
          await updateDoc(orgDocRef, {
            [roleUidField]: temp,
          });
          setEmail("");
          setSelectedRole("");
          alert.show(t("alert.invitationSuccess"), { type: "success" });
          setLoader(false);
        } catch (err) {
          alert.show(t("alert.somethingWentWrong"), {
            type: "error",
          });
          console.error(t("error.updatingDoc"), err);
          setLoader(false);
        }
      }
    } else {
      const roleEmailField = `${selectedRole.toLowerCase()}Email`;
      if (selectedOrg[roleEmailField]?.includes(inviteEmail)) {
        alert.show(t("alert.userExists"), { type: "info" });
        setLoader(false);
      } else {
        let temp = [...(selectedOrg[roleEmailField] || [])];
        temp.push(inviteEmail);

        try {
          await updateDoc(orgDocRef, {
            [roleEmailField]: temp,
          });
          setEmail("");
          setSelectedRole("");
          alert.show(t("alert.tempInviteSuccess"), {
            type: "success",
          });
          setLoader(false);
        } catch (err) {
          alert.show(t("alert.somethingWentWrong"), {
            type: "error",
          });
          console.error(t("error.updatingDoc"), err);
          setLoader(false);
        }
      }
    }
  };

  const deleteMember = async (member) => {
    const roleToUidMapping = {
      admin: "adminUid",
      cashier: "cashierUid",
      steward: "stewardUid",
    };

    const roleUids = roleToUidMapping[member.roleInOrg];

    if (!roleUids) {
      alert.show(t("alert.invalidRole"), { type: "error" });
      return;
    }

    try {
      const orgDocRef = doc(db, "Organizations", selectedOrg.id);
      const updatedUids = selectedOrg[roleUids].filter(
        (uid) => uid !== member.uid
      );

      await updateDoc(orgDocRef, {
        [roleUids]: updatedUids,
      });

      setMembers((prevMembers) =>
        prevMembers.filter((m) => m.uid !== member.uid)
      );
      alert.show(t("alert.memberRemoved"), { type: "success" });
    } catch (error) {
      console.error(t("error.failedDeleteMember"), error);
      alert.show(t("alert.failedRemoveMember"), { type: "error" });
    }
  };

  const createNewOrganization = async () => {
    if (!title || !selectedActivity) {
      alert.show(t("alert.fillAllRequiredFields"), { type: "info" });
      return;
    }
    setLoader(true);
    const uid = userStore.users.uid;
    let id = new Date().valueOf().toString();
    let data = {
      id: id,
      organization: title,
      activity: selectedActivity,
      logo: image,
      createdAt: id,
      uid,
      isDataUpdated: true,
      cashierUid: [],
      stewardUid: [],
      adminUid: [uid],
      adminEmail: [],
      cashierEmail: [],
      liquidity: 0,
      slate: true,
      stewardEmail: [],
      vivawalletActivated: false,
    };

    try {
      await setDoc(doc(db, "Organizations", id), data);
      alert.show(t("alert.orgCreatedSuccess"), { type: "success" });
    } catch (error) {
      alert.show(t("alert.failedCreateOrg"), { type: "error" });
      console.error(t("error.creatingDoc"), error);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (orgId[0]) {
      const findOrg = organizations.find((org) => org.id === orgId[0]);
      setSelectedOrg(findOrg);
      setImage(findOrg?.logo ? findOrg.logo : defaultLogo);
      setTitle(findOrg?.organization ? findOrg.organization : "");
      setSelectedActivity(findOrg?.activity ? findOrg.activity : "");
      getMembersFromDb(findOrg);
    } else {
      setSelectedOrg();
      setImage(defaultLogo);
      setTitle("");
      setSelectedActivity("");
    }
  }, [orgId, organizations]);

  return (
    <>
      <div className="container-fluid dashboardMain">
        <div className="row mx-0">
          <div className="col-xl-8">
            <div className="organization-card">
              <div className="card themeCard">
                <div className="card-header"></div>
                <div className="card-body p-sm-3 pt-0 pt-sm-0">
                  <div className="d-flex align-items-end orgs-author">
                    <img src={image} alt="" className="orgs-logo" />
                    <h5 className="orgs-name">
                      {selectedOrg?.organization || t("newSalesPoint")}
                    </h5>
                  </div>
                  {orgId[0] ? (
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="row gx-0 gy-4">
                        <div className="col-sm-6 sm-border-right pr-sm-20">
                          <div className="mb-3 sm-min-h-100px">
                            <h5 className="title mb-2">
                              {t("editSalesPoint")}
                            </h5>
                          </div>
                          <div className="mb-20">
                            <Input
                              label={t("salesPointName")}
                              placeholder={t("enterName")}
                              id="title"
                              value={title}
                              onChange={(e) => {
                                setTitle(e.target.value);
                              }}
                              required="required"
                            />
                          </div>
                          <div className="mb-20">
                            <Dropdown
                              label={t("activityType")}
                              options={activity}
                              value={selectedActivity}
                              onChange={(e) => setSelectedActivity(e)}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 pl-sm-20">
                          <div className="mb-3 sm-min-h-100px">
                            <h5 className="title mb-2">{t("addMembers")}</h5>
                            <p>{t("allowMembers")}</p>
                          </div>
                          <div className="mb-20">
                            <Input
                              label={t("email")}
                              placeholder={t("enterEmail")}
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                          </div>
                          <div className="mb-20">
                            <Dropdown
                              label={t("role")}
                              options={roles}
                              value={selectedRole}
                              onChange={(e) => setSelectedRole(e.value)}
                            />
                          </div>
                          <br />
                          <button
                            type="button"
                            className="themeBtn m-0 w-100 pos-submit-btn"
                            onClick={inviteHandler}
                          >
                            {t("invite")}
                          </button>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-5 pt-xl-5">
                        <button
                          type="submit"
                          className="themeBtn m-0 px-10 pos-submit-btn w-180px"
                          onClick={saveForm}
                        >
                          {t("save")}
                        </button>
                      </div>
                    </form>
                  ) : (
                    <form
                      className="mb-30"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <div className="row gx-0 gy-4">
                        <div className="mb-3 sm-min-h-100px">
                          <h5 className="title mb-2">{t("newSalesPoint")}</h5>
                        </div>
                        <div className="mb-20">
                          <Input
                            label={t("salesPointName")}
                            placeholder={t("enterName")}
                            id="title"
                            value={title}
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                            required="required"
                          />
                        </div>
                        <div className="mb-30">
                          <Dropdown
                            label={t("activityType")}
                            options={activity}
                            value={selectedActivity}
                            onChange={(e) => setSelectedActivity(e)}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-5 pt-xl-5">
                        <button
                          type="submit"
                          className="themeBtn m-0 px-10 pos-submit-btn w-180px"
                          onClick={createNewOrganization}
                        >
                          {t("create")}
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
          {orgId[0] && (
            <div className="col-xl-4">
              <div className="amountMainCard themeCard right-side-bar-content">
                <div className="item">
                  <h5 className="subtitle mb-2">{t("listOfMembers")}</h5>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="d-flex flex-column gap-12px mb-20">
                      {members.map((item, index) => (
                        <div className="right-sidebar-history-item" key={index}>
                          <div className="cont">
                            <h6>{item?.username}</h6>
                            <p className="m-0">{item?.roleInOrg}</p>
                          </div>
                          <svg
                            width="26"
                            height="27"
                            viewBox="0 0 26 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => deleteMember(item)}
                          >
                            <path
                              d="M18.4166 7.00008H23.8333V9.16675H21.6666V23.2501C21.6666 23.5374 21.5525 23.8129 21.3493 24.0161C21.1462 24.2193 20.8706 24.3334 20.5833 24.3334H5.41663C5.12931 24.3334 4.85376 24.2193 4.65059 24.0161C4.44743 23.8129 4.33329 23.5374 4.33329 23.2501V9.16675H2.16663V7.00008H7.58329V3.75008C7.58329 3.46276 7.69743 3.18721 7.90059 2.98405C8.10376 2.78088 8.37931 2.66675 8.66663 2.66675H17.3333C17.6206 2.66675 17.8962 2.78088 18.0993 2.98405C18.3025 3.18721 18.4166 3.46276 18.4166 3.75008V7.00008ZM9.74996 12.4167V18.9167H11.9166V12.4167H9.74996ZM14.0833 12.4167V18.9167H16.25V12.4167H14.0833ZM9.74996 4.83341V7.00008H16.25V4.83341H9.74996Z"
                              fill="#FF2F04"
                            />
                          </svg>
                        </div>
                      ))}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export { OrganizationsPage };
