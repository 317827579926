import React, { useEffect, useState } from "react";
import {
  Text,
  Font,
  Page,
  View,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import QRCode from "qrcode";

import Header from "./Header";
import Skills from "./Skills";
import Education from "./Education";
import Details from "./Details";
import logo from "../img/comitty-logo.png";

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  container: {
    flex: 1,
    flexDirection: "row",
    "@media max-width: 400": {
      flexDirection: "column",
    },
  },
  image: {
    marginBottom: 10,
  },
  leftColumn: {
    flexDirection: "column",
    width: 170,
    paddingTop: 30,
    paddingRight: 15,
    "@media max-width: 400": {
      width: "100%",
      paddingRight: 0,
    },
    "@media orientation: landscape": {
      width: 200,
    },
  },
  footer: {
    fontSize: 12,
    fontFamily: "Poppins Bold",
    textAlign: "center",
    marginTop: 15,
    paddingTop: 5,
    borderWidth: 3,
    borderColor: "gray",
    borderStyle: "dashed",
    "@media orientation: landscape": {
      marginTop: 10,
    },
  },
});

Font.register({
  family: "Open Sans",
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
  family: "Poppins",
  src: `https://fonts.gstatic.com/s/poppins/v1/VIeViZ2fPtYBt3B2fQZplvesZW2xOQ-xsNqO47m55DA.ttf`,
});

Font.register({
  family: "Poppins Italic",
  src: `https://fonts.gstatic.com/s/poppins/v1/4WGKlFyjcmCFVl8pRsgZ9vesZW2xOQ-xsNqO47m55DA.ttf`,
});

Font.register({
  family: "Poppins Bold",
  src: `https://fonts.gstatic.com/s/poppins/v1/-zOABrCWORC3lyDh-ajNnPesZW2xOQ-xsNqO47m55DA.ttf`,
});

const Resume = (props) => (
  <Page {...props} style={styles.page}>
    <Header title={props.eventName} />
    <View style={styles.container}>
      <View style={styles.leftColumn}>
        {props.qrcode && <Image src={logo} style={styles.image} />}
        {props.qrcode && <Image src={props.qrcode} style={styles.image} />}
      </View>
      {props.tickets && (
        <Details tickets={props.tickets} details={props.details} />
      )}
    </View>
    <Text style={styles.footer}>
      La version numérique suffit, n'imprimez ce document que si nécessaire.
    </Text>
  </Page>
);

const TicketModel = (props) => {
  if (props.subdivision) {
    return (
      <Document
        author="Comitty"
        keywords="comitty, ticket, event"
        subject={"Vos tickets pour " + props.eventName}
        title="Tickets"
      >
        {props.tickets.map((ticket, index) => (
          <Resume
            size="A4"
            qrcode={props.id[index]}
            eventImage={logo}
            details={props.details}
            eventName={props.eventName}
            tickets={[ticket]}
          />
        ))}
      </Document>
    );
  }

  return (
    <Document
      author="Comitty"
      keywords="comitty, ticket, event"
      subject={"Vos tickets pour " + props.eventName}
      title="Tickets"
    >
      <Resume
        size="A4"
        qrcode={props.id}
        eventImage={logo}
        tickets={props.tickets}
        details={props.details}
        eventName={props.eventName}
      />
    </Document>
  );
};

export default TicketModel;
