import { useAlert } from "react-alert";
import { getAuth, signOut } from "firebase/auth";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Blocks } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import userAction from "../../../../redux/users/action";

import dropIco from "../../../../images/dropIco.svg";
import sideMenu from "../../../../images/sideMenu.svg";
import userPic from "../../../../images/user.png";
import "../global.css";

function Navbar({
  setDateRange,
  startDate,
  endDate,
  setDateClickHandle,
  dateClickHandle,
  setDateResetHandle,
  dateResetHandle,
  setPaymentMethod,
}) {
  const { t } = useTranslation("navbar");
  const { next } = useParams();
  const dispatch = useDispatch();
  const { setUser } = userAction;
  const userStore = useSelector((state) => state.userReducer);

  const alert = useAlert();

  const [loader, setLoader] = useState(false);

  const logout = async () => {
    try {
      setLoader(true);
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          dispatch(setUser());
          alert.show(t("logout_success"));
          setLoader(false);
        })
        .catch((error) => {
          console.log(error);
          alert.show(t("logout_error"));
          setLoader(false);
        });
    } catch (e) {
      alert.show(t("logout_error"));
      setLoader(false);
    }
  };

  return (
    <>
      <nav
        className="navbar jc-sb navbar-expand navbar-light topbar static-top pe-3"
        style={{ marginBottom: "25px" }}
      >
        <button
          id="sidebarToggleTop"
          className="btn btn-link rounded-circle mr-3"
        >
          <img className="img-fluid" src={sideMenu} alt="" />
        </button>
        {next === "overview" && (
          <>
            <div className="inputDiv">
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
              />
              <div className="submitDateMain">
                <i
                  title={t("submit")}
                  class="fas fa-paper-plane"
                  onClick={() => setDateClickHandle(!dateClickHandle)}
                ></i>
              </div>
            </div>
            <div className="inputDiv">
              <select
                className="form-control"
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                <option value="" disabled selected>
                  {t("payment_method")}
                </option>
                <option value="all">{t("all")}</option>
                <option value="card">{t("card")}</option>
                <option value="cash">{t("cash")}</option>
              </select>
              <img className="dropIco" src={dropIco} alt="" />
            </div>
            <i
              title={t("reset_filter")}
              className="fas fa-sync-alt resetIcon ms-2"
              onClick={() => setDateResetHandle(!dateResetHandle)}
            ></i>
          </>
        )}
        <ul className="navbar-nav ms-auto">
          <li className="dropdown no-arrow nav-item ">
            <a
              className="nav-link profileLink dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="profile-div me-2">
                <div className="mr-2 profile-name">
                  {userStore?.users?.username ||
                    userStore?.users?.email.split("@")[0]}
                </div>
                <span className="status">{userStore?.users?.email}</span>
              </div>
              <div className="img-profile rounded-circle">
                <img
                  className="img-fluid"
                  src={
                    userStore?.users?.profilePic
                      ? userStore?.users?.profilePic
                      : userPic
                  }
                />
              </div>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <a className="dropdown-item" href="#" onClick={logout}>
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                {t("logout")}
              </a>
            </div>
          </li>
        </ul>
      </nav>
      {loader && (
        <div className="blockLoader">
          <Blocks
            visible={loader}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          />
        </div>
      )}
    </>
  );
}

export default Navbar;
