import React, { useState, useEffect } from "react";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { useAlert } from "react-alert";
import { pdf } from "@react-pdf/renderer";
import QRCode from "qrcode";
import { db } from "../firebase";

import ENV from "../env";
import Input from "./Input";
import TicketItem from "./TicketItem";
import LoadingSpinner from "../components/LoadingSpinner";
import ClosedSpinner from "../components/ClosedSpinner";
import {
  sendQRCode,
  createParticipant,
  createStripePayment,
  createStripeFreePayment,
} from "../utility";
import TicketModel from "./pdf";
import * as moment from "moment";
import "moment/locale/fr";

moment.locale("fr-FR");

const EventDesc = (props) => {
  const isOpen = props.event.open;
  const eventId = props.event.id;
  const orgId = props.event.orgId;
  const feeDelegated = props.event.feeDelegated
    ? props.event.feeDelegated
    : false;
  const ticketSubdivision = props.event.subdivision
    ? props.event.subdivision
    : false;
  const endDate = new Date(props.event.endDate);
  const [tickets, setTickets] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [numTickets, setNumTickets] = useState(0);
  const [fields, setFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const alert = useAlert();
  var moment = require("moment");

  const saleEnded = moment(endDate).isBefore();

  const loadTickets = async () => {
    const ticketsRef = collection(db, "Events", eventId, "Tickets");
    let loadedTickets;

    await getDocs(ticketsRef)
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        loadedTickets = data;
      })
      .catch((err) => {
        console.log("Fetch tickets failed: " + err);
      });

    setTickets(loadedTickets);
  };

  const setTicketsHandler = (newTickets) => {
    setSelectedTickets(newTickets);
    let newNumTickets = 0;
    let newTotalAmount = 0;
    newTickets.forEach((item) => {
      newNumTickets += parseInt(item.number);
      newTotalAmount += parseFloat(item.sum);
    });
    setNumTickets(newNumTickets);
    setTotalAmount(newTotalAmount);
  };

  const saveForm = (e) => {
    e.preventDefault();

    const customFieldsLength = props.event.formFields.length + 1;

    if (fields.length < customFieldsLength) {
      alert.show("Veuillez remplir toutes les champs du formulaire!");
    } else {
      alert.show("Formulaire complet!", { type: "success" });
    }
  };

  const inputChangeHandler = (
    changeEvent: React.ChangeEvent<HTMLInputElement>
  ) => {
    const existingField = fields.findIndex(
      (element) => element.name === changeEvent.target.id
    );

    const newFields = fields;
    if (existingField >= 0) {
      newFields[existingField] = {
        name: changeEvent.target.id,
        value: changeEvent.target.value,
      };
    } else {
      newFields.push({
        name: changeEvent.target.id,
        value: changeEvent.target.value,
      });
    }
    setFields(newFields);
  };

  const customFields = props.event.formFields.map((field) => {
    switch (field) {
      case "Name":
        break;
      case "Email":
        break;
      case "Address":
        return (
          <div className="col-12">
            <Input
              placeholder="Pl. de l'Atomium, 1020 Bruxelles"
              label="Adresse"
              type="text"
              id="Address"
              onChange={(e) => inputChangeHandler(e)}
              required="required"
            />
          </div>
        );
        break;
      case "Phone Number":
        return (
          <div className="col-12">
            <label className="form-label __form-label" htmlFor="phone number">
              numéro de téléphone
            </label>
            <div className="d-flex" style={{ gap: "15px" }}>
              <input
                className="form-control __form-control w-0 flex-grow"
                placeholder="+32 470 000 000"
                type="text"
                id="Phone Number"
                required="required"
                onChange={(e) => inputChangeHandler(e)}
              />
            </div>
          </div>
        );
        break;
      default:
        return (
          <div className="col-12">
            <Input
              placeholder={`Ajouter ${field}`}
              label={field}
              required="required"
              type="text"
              id={field}
              onChange={(e) => inputChangeHandler(e)}
            />
          </div>
        );
    }
  });

  const checkoutHandler = async (paymentMethod) => {
    const customFieldsLength = props.event.formFields.length + 1;

    if (numTickets === 0) {
      alert.show("Veuillez séléctionner au moins un ticket!", {
        type: "error",
      });
    } else if (fields.length < customFieldsLength) {
      setIsLoading(true);
      alert.show("Veuillez remplir tous les champs du formulaire!", {
        type: "error",
      });
    } else {
      setIsLoading(true);
      const participantField = fields.find(
        (element) => element.name === "Email"
      );
      const participantMail = participantField.value.trim();
      const participantPhoneNumber = fields.find(
        (element) => element.name === "Phone Number"
      );
      if (!participantMail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        alert.show("e-mail invalide!", { type: "error" });
      } else if (
        participantPhoneNumber &&
        !participantPhoneNumber.value.startsWith("+")
      ) {
        alert.show(
          "Veuillez entrer votre numéro de téléphone avec le préfix! (EX: +32)",
          { type: "error" }
        );
      } else {
        if (totalAmount === 0) {
          await createParticipant(
            eventId,
            selectedTickets,
            fields,
            ticketSubdivision
          )
            .then(async (participantId) => {
              alert.show("Inscription réussie!", { type: "success" });
              window.open(
                `${ENV.hostname}/checkout/${eventId}/${participantId}/success`,
                "_parent",
                "noreferrer"
              );
            })
            .catch((err) => {
              alert.show("L'inscription n'a pas fonctionnée!", {
                type: "error",
              });
              console.log("Failed to save the participant: " + err);
            });
        } else {
          await createStripePayment(
            totalAmount,
            selectedTickets,
            fields,
            participantMail,
            orgId,
            eventId,
            paymentMethod,
            feeDelegated,
            ticketSubdivision
          )
            .then(async () => {
              alert.show("Payment réussi!", { type: "success" });
            })
            .catch((err) => {
              alert.show("Le paiement n'a pas fonctionnée!", { type: "error" });
              console.log("Failed to save the participant: " + err);
            });
        }
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (props.event) {
      loadTickets();
    }
  }, [eventId]);

  return (
    <section className="event-desc-section">
      <div className="container">
        <div className="section-header">
          <h2 className="title">Description</h2>
          <div className="text">
            {props.event
              ? props.event.description.split("\n").map((i, key) => {
                  return <div key={key}>{i}</div>;
                })
              : ""}
          </div>
        </div>
        {(!isOpen || saleEnded) && (
          <div className="section-header">
            <h2 className="title">Cette vente est fermée!</h2>
            <ClosedSpinner height="50%" width="50%" />
            {/* <div className="text">Cette page n'existe pas.</div> */}
            <div className="col-12">
              <button
                type="submit"
                className="submitBtn"
                onClick={() => {
                  window.open(`https://comitty.be`, "_parent", "noreferrer");
                }}
              >
                Retour sur Comitty
              </button>
            </div>
          </div>
        )}
        {isOpen && !saleEnded && (
          <div className="__details-wrapper">
            <form className="__details-form" onSubmit={saveForm}>
              <h3 className="__details-form-title">Remplissez le formulaire</h3>
              <div className="row g-2 g-lg-3">
                <div className="col-6">
                  <Input
                    placeholder="Ajouter Prénom"
                    label="Prénom"
                    type="text"
                    id="Firstname"
                    onChange={(e) => inputChangeHandler(e)}
                    required="required"
                  />
                </div>
                <div className="col-6">
                  <Input
                    placeholder="Ajouter Nom"
                    label="Nom"
                    type="text"
                    id="Name"
                    onChange={(e) => inputChangeHandler(e)}
                    required="required"
                  />
                </div>
                <div className="col-12">
                  <Input
                    placeholder="@mail.com"
                    label="adresse e-mail"
                    type="text"
                    id="Email"
                    onChange={(e) => inputChangeHandler(e)}
                    required="required"
                  />
                  <div className="text-sm mt-2">
                    <span className="me-1">
                      <svg
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 5.05285L9.43152 3.12873L5.83979 4.48374L5.96899 0.5H4.05685L4.16021 4.45664L0.594315 3.12873L0 5.05285L3.64341 6.16396L1.31783 9.30759L2.86822 10.5L5.01292 7.16667L7.15762 10.5L8.70801 9.30759L6.38243 6.16396L10 5.05285Z"
                          fill="#EB5757"
                        />
                      </svg>
                    </span>
                    Attention : entrez un email valide pour pouvoir récupérer
                    vos billets
                  </div>
                </div>
                {customFields}
                <div className="col-12">
                  <button type="submit" className="submitBtn">
                    Valider
                  </button>
                </div>
              </div>
            </form>
            {tickets.length ? (
              <div className="__ticket-wrapper">
                <h2 className="__ticket-wrapper-title">
                  Choisissez vos billets
                </h2>
                {tickets.length > 10
                  ? tickets.map((ticket) => (
                      <TicketItem
                        ticket={ticket}
                        totalAmount={totalAmount}
                        setTotalAmount={setTotalAmount}
                        numTickets={numTickets}
                        setNumTickets={setNumTickets}
                        selectedTickets={selectedTickets}
                        setSelectedTickets={setSelectedTickets}
                        setTicketsHandler={setTicketsHandler}
                      />
                    ))
                  : tickets
                      .sort((a, b) => Number(a.price) - Number(b.price))
                      .map((ticket) => (
                        <TicketItem
                          ticket={ticket}
                          totalAmount={totalAmount}
                          setTotalAmount={setTotalAmount}
                          numTickets={numTickets}
                          setNumTickets={setNumTickets}
                          selectedTickets={selectedTickets}
                          setSelectedTickets={setSelectedTickets}
                          setTicketsHandler={setTicketsHandler}
                        />
                      ))}
                <div className="__ticket-checkout">
                  <div>
                    <div className="subtitle">Montant Total</div>
                    <h4 className="total-price">{totalAmount} €</h4>
                  </div>
                  {isLoading ? (
                    <LoadingSpinner height="30%" width="30%" />
                  ) : (
                    <div>
                      {totalAmount > 0 ? (
                        <div>
                          <button
                            onClick={() => checkoutHandler("bancontact")}
                            className="checkoutBtn"
                          >
                            Payer avec Bancontact
                          </button>
                          <button
                            onClick={() => checkoutHandler("card")}
                            className="checkoutBtn"
                          >
                            Payer avec Mastercard/Visa
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button
                            onClick={() => checkoutHandler("bancontact")}
                            className="checkoutBtn"
                          >
                            Confirmer
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <LoadingSpinner height="30%" width="30%" />
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default EventDesc;
